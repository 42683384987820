import { CacheService } from './shared/services/cache.service';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private cacheService: CacheService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(map(res => res), catchError((error: HttpErrorResponse) => {
      if (!request.headers.get('Anonymous')) {
        console.log(' ERROR ', error);
        if (!this.cacheService.massivoInCorso) {
          if (error?.status >= 500) {
            const message = "Si è verificato un errore imprevisto, riprovare più tardi. Se l'errore persiste contattare il gestore";
            this.cacheService.error.next({ message });
            return throwError(() => message);
          }
          this.cacheService.error.next(error?.error);
          return throwError(() => error?.error?.message);
        }
      }
    }));
  }
}
