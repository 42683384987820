<div class="col-12" *ngIf="!isItFromParte">
  <h5>Anagrafica Avvocato</h5>
</div>

<div class="col-12 avvocato-form">
  <form nz-form [formGroup]="form" *ngIf="form">
    <div class="row">
      <div class="col-12 py-3 mb-2 autocomplete-notification-container">
        <div class="row" *ngIf="isItFromParte">
          <div class="col-6">
            <nz-form-item>
              <div class="row w-100">
                <div class="col-4 d-flex justify-content-end">
                  <nz-form-label nzFor="tipoPersona">Persona</nz-form-label>
                </div>
                <div class="col-8 d-flex align-items-center">
                  <nz-radio-group formControlName="tipoPersona" (ngModelChange)="onChangePersona($event)">
                    <label nz-radio nzValue="PersonaFisica">Persona Fisica</label>
                    <label nz-radio nzValue="PersonaGiuridica">Persona Giuridica</label>
                  </nz-radio-group>
                </div>
              </div>
            </nz-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <nz-form-item>
              <div class="row w-100">
                <div class="col-4 d-flex justify-content-end">
                  <nz-form-label nzFor="codiceFiscale" nzRequired>CF</nz-form-label>
                </div>
                <div class="col-8">
                  <nz-form-control [nzErrorTip]="codiceFiscaleErrorTpl">
                    <input #codiceFiscale nz-input id="codiceFiscale" formControlName="codiceFiscale" placeholder="CF" (input)="codiceFiscale.value = codiceFiscale.value.toUpperCase(); onTypingCompileCodiceFiscale(codiceFiscale.value, $event)" [nzAutocomplete]="codiceAutocomplete">
                  </nz-form-control>
                  <nz-autocomplete #codiceAutocomplete>
                    <nz-auto-option *ngFor="let option of listAnagrafica" [nzValue]="option.codiceFiscale" (click)="toggleFields(['codiceFiscale', 'cognome'], true, 'codiceFiscale', option.id)">{{option.codiceFiscale}} - {{option.nomeCognome}}</nz-auto-option>
                  </nz-autocomplete>
                  <ng-template #codiceFiscaleErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('required')">Campo obbligatorio</ng-container>
                    <ng-container *ngIf="control.hasError('pattern')">Inserisci un codice valido con 16 caratteri alfanumerici</ng-container>
                  </ng-template>
                </div>
              </div>
            </nz-form-item>
          </div>

          <div class="col-6">
            <span class="f-16">Inserire il Codice Fiscale o il Cognome per la compilazione automatica degli altri campi.</span>
          </div>

          <div class="col-6">
            <nz-form-item>
              <div class="row w-100">
                <div class="col-4 d-flex justify-content-end">
                  <nz-form-label nzFor="cognome" nzRequired>Cognome</nz-form-label>
                </div>
                <div class="col-8">
                  <nz-form-control nzErrorTip="Campo obbligatorio">
                    <input #cognome required nz-input id="cognome" formControlName="cognome" placeholder="Cognome" (input)="onTypingCognome(cognome.value)" [nzAutocomplete]="cognomeAutocomplete">
                  </nz-form-control>
                  <nz-autocomplete #cognomeAutocomplete>
                    <nz-auto-option *ngFor="let option of listAnagrafica" [nzValue]="option.codiceFiscale" (click)="toggleFields(['codiceFiscale', 'cognome'], true, 'cognome', option.id)">{{option.codiceFiscale}} - {{option.nomeCognome}}</nz-auto-option>
                  </nz-autocomplete>
                </div>
              </div>
            </nz-form-item>
          </div>
        </div>
      </div>

      <div class="col-6">
        <nz-form-item>
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="nome" nzRequired>Nome</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control nzErrorTip="Campo obbligatorio">
                <input required nz-input id="nome" formControlName="nome" placeholder="Nome">
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>

        <nz-form-item>
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="dataNascita">Data di nascita</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control>
                <nz-date-picker id="dataNascita" formControlName="dataNascita" nzPlaceHolder="__/__/____" [nzFormat]="'dd/MM/yyyy'" class="w-100"></nz-date-picker>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>

        <nz-form-item>
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="cittaNascita">Comune di nascita</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear formControlName="cittaNascita" nzPlaceHolder="Comune di nascita">
                  <nz-option *ngFor="let option of comuniList" [nzLabel]="option.description" [nzValue]="option.description"></nz-option>
                </nz-select>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>

        <nz-form-item>
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="provinciaNascita">Provincia di nascita</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear formControlName="provinciaNascita" nzPlaceHolder="Provincia di nascita">
                  <nz-option *ngFor="let option of provinceList" [nzLabel]="option.description" [nzValue]="option.code"></nz-option>
                </nz-select>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>

        <nz-form-item *ngIf="isItFromParte && !shouldBeAllDisabled">
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="rappresentata">Parte rappresentata</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control>
                <label nz-checkbox formControlName="rappresentata" id="rappresentata"></label>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>

        <nz-form-item *ngIf="shouldBeAllDisabled && isItFromParte">
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label>Utente</nz-form-label>
            </div>
            <div class="col-8 d-flex align-items-center">
              <span [class.fish-eye-success]="costituito" [class.fish-eye-error]="!costituito">&#9673;</span>
              <span class="costituito ml-2">{{costituito ? "Costituito" : "Non costituito"}}</span>
            </div>
          </div>
        </nz-form-item>

        <nz-form-item *ngIf="!isItFromParte">
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzTooltipTitle="Inserendo questo campo verrai identificato come Avvocato di una Parte" nzFor="albo">Albo</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control>
                <input nz-input id="albo" formControlName="albo" placeholder="Albo">
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>

        <nz-form-item *ngIf="!isItFromParte">
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzTooltipTitle="Inserendo questo campo verrai identificato come Avvocato di una Parte" nzFor="ordine">Ordine</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control>
                <input nz-input id="ordine" formControlName="ordine" placeholder="Ordine">
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>
      </div>

      <div class="col-6">
        <nz-form-item>
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzRequired nzFor="email">Email</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control [nzErrorTip]="emailErrorTpl">
                <input required nz-input id="email" formControlName="email" placeholder="Email">
                <ng-template #emailErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">Campo obbligatorio</ng-container>
                  <ng-container *ngIf="control.hasError('email')">Inserisci un indirizzo mail valido!</ng-container>
                </ng-template>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>

        <nz-form-item>
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label *ngIf="!isItFromParte" nzRequired nzFor="pec">Pec</nz-form-label>
              <nz-form-label *ngIf="isItFromParte" nzFor="pec">Pec</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control [nzErrorTip]="pecErrorTpl">
                <input nz-input id="pec" formControlName="pec" placeholder="Pec">
                <ng-template #pecErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">Campo obbligatorio</ng-container>
                  <ng-container *ngIf="control.hasError('email')">Inserisci un indirizzo pec valido!</ng-container>
                </ng-template>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>

        <nz-form-item>
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="numeroTelefono">Cellulare</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control>
                <nz-input-group>
                  <input type="number" formControlName="numeroTelefono" id="numeroTelefono" placeholder="000 000 00 00" nz-input>
                </nz-input-group>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control [nzErrorTip]="capResidenzaErrTemplate">
            <div class="row w-100">
              <div class="col-4 d-flex justify-content-end">
                <nz-form-label nzFor="capResidenza">
                  <span *ngIf="isItFromParte">CAP residenza</span>
                  <span *ngIf="!isItFromParte">CAP studio legale</span>
                </nz-form-label>
              </div>
              <div class="col-8">
                <input #capResidenza nz-input nz-input id="capResidenza" formControlName="capResidenza" placeholder="CAP" type="number" (keypress)="keyPressNumbers($event, 'capResidenza')">
              </div>
              <ng-template #capResidenzaErrTemplate let-control>
                <ng-container *ngIf="control.hasError('pattern')">Il campo deve contenere 5 cifre</ng-container>
              </ng-template>
            </div>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="cittaResidenza">
                <span *ngIf="isItFromParte">Città residenza</span>
                <span *ngIf="!isItFromParte">Città studio legale</span>
              </nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear formControlName="cittaResidenza" nzPlaceHolder="Comune di residenza">
                  <nz-option *ngFor="let option of comuniList" [nzLabel]="option.description" [nzValue]="option.description"></nz-option>
                </nz-select>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>

        <nz-form-item>
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="indirizzoResidenza">
                <span *ngIf="isItFromParte">Indirizzo residenza</span>
                <span *ngIf="!isItFromParte">Indirizzo studio legale</span>
              </nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control>
                <input nz-input id="indirizzoResidenza" formControlName="indirizzoResidenza" placeholder="Indirizzo residenza">
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>

        <nz-form-item *ngIf="!shouldBeAllDisabled">
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="procura" [nzRequired]="!isItFromParte">
                <span *ngIf="!isItFromParte">Procura alle Liti</span>
                <span *ngIf="isItFromParte">Documenti identità</span>
              </nz-form-label>
            </div>
            <div class="col-8">
              <app-uploader [allowMultiple]="isItFromParte" (cancelId)="handleDocumentDeletion($event)" (addId)="handleAddDocument($event)" #uploader></app-uploader>
              <div class="error-text mt-2" *ngIf="documentControl.hasError('required') && documentControl.dirty">Campo obbligatorio</div>
            </div>
          </div>
        </nz-form-item>
      </div>
    </div>
  </form>
</div>
