import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indeterminato'
})
export class IndeterminatoPipe implements PipeTransform {
  transform(value: number) {
    switch (value) {
      case -1:
        return 'Indeterminato Basso';
      case -2:
        return 'Indeterminato Medio';
      case -3:
        return 'Indeterminato Alto';
    }
  }
}
