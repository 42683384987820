import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AnagraficaParteComponent } from './components/anagrafica-parte/anagrafica-parte.component';
import { AvvocatoFormComponent } from './components/anagrafica-parte/avvocato-form/avvocato-form.component';
import { ParteFormComponent } from './components/anagrafica-parte/parte-form/parte-form.component';
import { CustomCalendarComponent } from './components/custom-calendar/custom-calendar.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { GiornoPipe } from './pipes/giorno.pipe';
import { NumberSortPipe } from './pipes/numberSort.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { TipoPersonaPipe } from './pipes/tipo-persona.pipe';
import { ThemeConstantService } from './services/theme-constant.service';
import { DettaglioNotificaComponent } from './tutte-notifiche/dettaglio-notifica/dettaglio-notifica.component';
import { TutteNotificheComponent } from './tutte-notifiche/tutte-notifiche.component';
import { IndeterminatoPipe } from './pipes/indeterminato.pipe';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export const antdModule = [
  NzIconModule,
  NzToolTipModule,
  NzCollapseModule,
  NzButtonModule,
  NzFormModule,
  NzInputModule,
  NzSelectModule,
  NzRadioModule,
  NzDatePickerModule,
  NzAutocompleteModule,
  NzTypographyModule,
  NzUploadModule,
  NzTagModule,
  NzCheckboxModule,
  NzBadgeModule,
  NzSpinModule,
  NzButtonModule,
  NzIconModule,
  NzFormModule,
  NzInputModule,
  NzSelectModule,
  NzRadioModule,
  NzTagModule,
  NzTableModule,
  NzDatePickerModule,
  NzSpinModule,
  NzToolTipModule,
  NzAutocompleteModule,
  NzCollapseModule,
  NzTypographyModule,
  NzUploadModule,
  NzBadgeModule,
  NzCheckboxModule
];

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NzIconModule,
    PerfectScrollbarModule,
    SearchPipe,
    ClickStopPropagationDirective,
    TranslateModule,
    StatusPipe,
    AnagraficaParteComponent,
    AvvocatoFormComponent,
    UploaderComponent,
    GiornoPipe,
    TipoPersonaPipe,
    TutteNotificheComponent,
    DettaglioNotificaComponent,
    CustomCalendarComponent,
    NumberSortPipe,
    IndeterminatoPipe
  ],
  imports: [
    ...antdModule,
    RouterModule,
    CommonModule,
    NzIconModule,
    NzToolTipModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    })
  ],
  declarations: [
    SearchPipe,
    ClickStopPropagationDirective,
    StatusPipe,
    AnagraficaParteComponent,
    ParteFormComponent,
    AvvocatoFormComponent,
    UploaderComponent,
    GiornoPipe,
    TipoPersonaPipe,
    CustomCalendarComponent,
    TutteNotificheComponent,
    DettaglioNotificaComponent,
    NumberSortPipe,
    IndeterminatoPipe
  ],
  providers: [ThemeConstantService],
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
